var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "about" },
    [
      _c(
        "v-card",
        { staticClass: "ma-3 pa-3" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { sm: "12", md: "12" } },
                [
                  _c(
                    "v-parallax",
                    {
                      attrs: {
                        dark: "",
                        src: require("@/assets/background-azul-escuro-reduzido-2.jpg")
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "h1",
                                {
                                  staticClass: "display-1 font-weight-thin mb-4"
                                },
                                [
                                  _vm._v(
                                    " Manual do Usuário e de Integração 2.0 "
                                  )
                                ]
                              ),
                              _c("h3", { staticClass: "heading" }, [
                                _vm._v("Recuperi | 2023")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { sm: "12", md: "12" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black display-1" },
                    [_vm._v("1. Início")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Obrigado por escolher a RECUPERI, sua parceira na cobrança de valores por todo o Brasil! O presente Manual do Usuário e de Integração (“Manual”) foi criado para facilitar o uso e acelerar os seus processos de integração com nossa Plataforma. Nosso Manual também possui explicações sobre as diversas as informações sobre os títulos necessárias para protesto, de acordo com os requisitos exigidos pela legislação aplicável e práticas adotadas pelos tabeliães de protesto. Dúvidas, orientações e solicitações específicas podem ser direcionadas para suporte@recuperi.com.br "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black display-1" },
                    [_vm._v("2. Nossa Plataforma(painel)")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Nosso Painel pode ser acessado por meio do link https://painel.recuperi.com.br/ ou em nosso site www.recuperi.com.br. As informações de acesso são enviadas individualmente e por solicitação do Apresentante ao nosso suporte, de modo a garantir a segurança dos usuários – e poderão ser solicitadas por meio do contato suporte@recuperi.com.br. "
                    )
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "grey--text font-weight-black title"
                            },
                            [_vm._v("2.1 Funcionalidades")]
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " Ao acessar nossa Plataforma, você terá acesso imediato ao seu Dashboard, que contém uma apresentação geral dos status dos seus títulos enviados. "
                            )
                          ]),
                          _c("v-card-text", [
                            _vm._v(
                              " Em nosso Dashboard também é possível ter acesso às diversas funcionalidades gerenciais da Plataforma, inclusive consulta de títulos por status, quantidade e valores protestados, evolução dos procedimentos de protesto, pagamento de títulos e histórico de importações. "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "8" } },
                        [
                          _c("v-img", {
                            staticClass: "ma-3",
                            attrs: {
                              "max-height": "700",
                              "max-width": "700",
                              src: require("@/assets/manual/screen-manual-1.png")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "ma-3 pa-3" },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dense: "",
                                    color: "primary white--text",
                                    width: "105%"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$vuetify.goTo(
                                        _vm.$refs.uploadTitulos
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" cloud_upload ")
                                  ]),
                                  _vm._v(" Upload de Títulos")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dense: "",
                                    color: "primary white--text",
                                    width: "105%"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$vuetify.goTo(
                                        _vm.$refs.consultaTitulos
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" search ")
                                  ]),
                                  _vm._v(" Consulta de Títulos")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dense: "",
                                    color: "primary white--text",
                                    width: "105%"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$vuetify.goTo(
                                        _vm.$refs.ofertas
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" local_offer ")
                                  ]),
                                  _vm._v(" Ofertas")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dense: "",
                                    color: "primary white--text",
                                    width: "105%"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$vuetify.goTo(
                                        _vm.$refs.enviarSms
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" message ")
                                  ]),
                                  _vm._v(" Enviar SMS")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dense: "",
                                    color: "primary white--text",
                                    width: "105%"
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" email ")
                                  ]),
                                  _vm._v(" Enviar E-mails")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dense: "",
                                    color: "primary white--text",
                                    width: "105%"
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" assignment ")
                                  ]),
                                  _vm._v(" Relatórios")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dense: "",
                                    color: "primary white--text",
                                    width: "105%"
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" map ")
                                  ]),
                                  _vm._v(" Mapa de Títulos")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dense: "",
                                    color: "primary white--text",
                                    width: "105%"
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" search ")
                                  ]),
                                  _vm._v(" Usuários")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dense: "",
                                    color: "primary white--text",
                                    width: "105%"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$vuetify.goTo(
                                        _vm.$refs.alteracoesLote
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" mdi-sync ")
                                  ]),
                                  _vm._v(" Alterações Lote")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { ref: "uploadTitulos" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "grey--text font-weight-black title" },
                        [_vm._v("2.1.1 Tela de Upload de Títulos: Visão geral")]
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " A funcionalidade upload de títulos permite o envio de títulos para protesto para cartórios de protesto em todos os Estados do Brasil. Ela é disponibilizada logo após a homologação de um novo Apresentante perante os cartórios de protesto e envio das informações de acesso. Para tanto, o usuário faz a importação de planilha com os dados dos títulos completando o respectivo template padrão, cumprindo nossas instruções para realizar o seu upload na www.recuperi.com.br 5 Plataforma. Maiores informações sobre as validações das informações encontram-se ao final do Manual, no capítulo Validações Básicas dos Títulos. Orientações adicionais também podem ser encontradas no Dicionário de Campos e no Glossário ao final do Manual. "
                    )
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "grey--text font-weight-black title"
                            },
                            [_vm._v("2.1.2 Upload de Títulos: procedimentos")]
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " Nossa Plataforma permite grande flexibilização em seu uso e adequação às necessidades e planejamento dos seus usuários. Conforme indicado anteriormente, uma das opções de envio de títulos para protesto é por meio da importação de planilhas Excel – opção que permite ao usuário manualmente importar suas bases na Plataforma. "
                            )
                          ]),
                          _c("v-card-text", [
                            _vm._v(
                              " A planilha a ser importada deve ser preenchida a partir do template padrão em Excel, com download através do botão disponível na funcionalidade Upload de Títulos. "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "8" } },
                        [
                          _c("v-img", {
                            staticClass: "ma-3",
                            attrs: {
                              "lazy-src": require("@/assets/manual/screen-manual-2.png"),
                              "max-height": "700",
                              "max-width": "700",
                              src: require("@/assets/manual/screen-manual-2.png")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Opções da Tela:")]
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", md: "6" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "grey--text font-weight-black title"
                            },
                            [_vm._v("Enviar")]
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " Após efetuar esse comando, a planilha Excel será validada pelos filtros de formatação da Plataforma: "
                            )
                          ]),
                          _c("v-card-text", [
                            _vm._v(
                              " Caso existam irregularidades, elas serão indicadas na coluna “Títulos Irregulares”. Com isso, após seu apontamento, o usuário poderá analisar estas possíveis irregularidades,pois se encontram discriminadas no Anexo “Irregularidades” ao final do Manual. Após superar as irregularidades, o usuário poderá reenviar, fazendo o upload, de arquivo com os dados deste título. "
                            )
                          ]),
                          _c("v-card-text", [
                            _vm._v(
                              " Caso existam título em revisão, eles aparecerão destacados em vermelho na respectivacoluna “Títulos em revisão”. Títulos em revisão possuem o nome do devedor ou oendereço com mais de 45 caracteres, mesmo após o encurtamento realizado pelaPlataforma e precisam ser manualmente corrigidas por meio da opção CORREÇÃO. "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", md: "3" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "grey--text font-weight-black title"
                            },
                            [_vm._v("Resultado")]
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " Download da planilha Excel enviada, com adição de uma coluna indicando possíveis irregularidades. "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", md: "3" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "grey--text font-weight-black title"
                            },
                            [_vm._v("Confirmar")]
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " Todos itens sem irregularidades ou em revisão serão enviados para protesto através da nossa Plataforma. "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "12", md: "4" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "grey--text font-weight-black title"
                            },
                            [_vm._v("Correção")]
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " a opção será disponibilizada para que sejam realizados ajustes nas informações do nome do devedor ou do endereço para cumprimento do limite máximo de 45 caracteres para que o envio para protesto seja liberado. "
                            )
                          ]),
                          _c("v-card-text", [
                            _vm._v(
                              " Para cada título em revisão, deve-se acessar a opção DETALHES (que pode ser acessada pelo ícone ) para proceder com sua correção. "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "8" } },
                        [
                          _c("v-img", {
                            staticClass: "ma-3",
                            attrs: {
                              "lazy-src": require("@/assets/manual/screen-manual-3.png"),
                              "max-height": "700",
                              "max-width": "700",
                              src: require("@/assets/manual/screen-manual-3.png")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { ref: "consultaTitulos" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "grey--text font-weight-black title" },
                        [_vm._v("2.1.3 Consulta de Títulos")]
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " A funcionalidade de Consulta de Títulos permite rápido acesso a informações e status de títulos individualmente ou em grupo, filtrados conforme a necessidade de acompanhamento. Ela também permite acesso à opção DETALHES (que pode ser acessada pelo ícone disponível para cada título consultado), onde o usuário poderá interagir com a Plataforma para solicitar cancelamento, anuência e desistência de protestos entre outras ações. "
                    )
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "8" } },
                        [
                          _c("v-img", {
                            staticClass: "ma-3",
                            attrs: {
                              "lazy-src": require("@/assets/manual/screen-manual-4.png"),
                              "max-height": "700",
                              "max-width": "700",
                              src: require("@/assets/manual/screen-manual-4.png")
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "grey--text font-weight-black title"
                            },
                            [_vm._v("Tela Consulta de Títulos")]
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " Utilize a tela Consulta de Títulos para encontrar qualquer título no sistema, podendo utilizar também filtros para localizar títulos específicos ou revisa-los. "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "grey--text font-weight-black title"
                            },
                            [_vm._v("Filtrar")]
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " Após o preenchimento dos campos de pesquisa de acordo com os filtros desejados, a opção filtrar permite a listagem com os títulos identificados pela Plataforma. Como exemplo, segue resultado de pesquisa de títulos pelo status “protesto solicitado”. "
                            )
                          ]),
                          _c(
                            "v-card-title",
                            {
                              staticClass: "grey--text font-weight-black title"
                            },
                            [_vm._v("Somente em Revisão")]
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " Filtro relevante, pois por ele é possível filtrar todos títulos que estão pendentes de revisão de todas planilhas importadas até a data corrente. É recomendável verificar se existem títulos pendentes de revisão, pois sem correção da informação pendente (como dados de endereço muito extensos) não será possível enviá-los para protesto. "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "8" } },
                        [
                          _c("v-img", {
                            staticClass: "ma-3",
                            attrs: {
                              "lazy-src": require("@/assets/manual/screen-manual-5.png"),
                              "max-height": "700",
                              "max-width": "700",
                              src: require("@/assets/manual/screen-manual-5.png")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "8" } },
                        [
                          _c("v-img", {
                            staticClass: "ma-3",
                            attrs: {
                              "lazy-src": require("@/assets/manual/screen-manual-6.png"),
                              "max-height": "700",
                              "max-width": "700",
                              src: require("@/assets/manual/screen-manual-6.png")
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "grey--text font-weight-black title"
                            },
                            [_vm._v("Detalhes do Título")]
                          ),
                          _c("v-card-text", [
                            _vm._v(
                              " A opção DETALHES pode ser acessada pelo ícone disponível para cada título consultado. O usuário será direcionado para tela que contém todos detalhes do título selecionado. "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Detalhes do Título")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " A funcionalidade de Consulta de Títulos permite rápido acesso a informações e status de títulos individualmente ou em grupo, filtrados conforme a necessidade de acompanhamento. Ela também permite acesso à opção DETALHES (que pode ser acessada pelo ícone disponível para cada título consultado), onde o usuário poderá interagir com a Plataforma para solicitar cancelamento, anuência e desistência de protestos entre outras ações. "
                    )
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    dense: "",
                                    color: "teal white--text",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Correção "),
                                  _c("v-icon", { staticClass: "ml-2" }, [
                                    _vm._v("build")
                                  ])
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                _vm._v(
                                  " Conforme explicado anteriormente, será disponibilizada para que seja realizada a correção nas informações sobre o título em revisão. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success darken-1",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Anuência "),
                                  _c("v-icon", { staticClass: "ml-2" }, [
                                    _vm._v("check")
                                  ])
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                _vm._v(
                                  " Será enviada uma solicitação de anuência para o título corrente na tela de DETALHES. Ação permitida apenas para títulos que estão com status de “Protestado”. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    dense: "",
                                    color: "error",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Cancelamento "),
                                  _c("v-icon", { staticClass: "ml-2" }, [
                                    _vm._v("cancel")
                                  ])
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                _vm._v(
                                  " Será enviada uma solicitação de cancelamento para o título corrente na tela de DETALHES. Ação permitida apenas para títulos que estão com status de “Protestado”. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blue white--text",
                                  attrs: {
                                    width: "100%",
                                    dense: "",
                                    color: "blue"
                                  }
                                },
                                [
                                  _vm._v(" Desistência "),
                                  _c("v-icon", { staticClass: "ml-2" }, [
                                    _vm._v("stop")
                                  ])
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                _vm._v(
                                  " Será enviada uma solicitação de desistência para o título corrente na tela de DETALHES. Ação permitida apenas para títulos que estão com status anteriores a “Protestado”. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Dentro da Consulta de Títulos também é possível a extração de informações e atualizações da evolução de status sobre todos os títulos enviados (ou conforme filtros selecionados), por meio de Relatórios simplificados ou detalhados: "
                    )
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blue white--text",
                                  attrs: {
                                    width: "100%",
                                    dense: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _vm._v(" PDF "),
                                  _c("v-icon", { staticClass: "ml-2" }, [
                                    _vm._v("cloud_download")
                                  ])
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                _vm._v(
                                  " Relatório simplificado, com resumo das principais informações dos títulos filtrados. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blue white--text",
                                  attrs: {
                                    width: "100%",
                                    dense: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _vm._v(" EXCEL "),
                                  _c("v-icon", { staticClass: "ml-2" }, [
                                    _vm._v("cloud_download")
                                  ])
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                _vm._v(
                                  " Relatório completo, com todas as informações dos títulos filtrados. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blue white--text",
                                  attrs: {
                                    width: "100%",
                                    dense: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _vm._v(" DETALHADO "),
                                  _c("v-icon", { staticClass: "ml-2" }, [
                                    _vm._v("cloud_download")
                                  ])
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                _vm._v(
                                  " Relatório de compilação dos status e das principais informações dos títulos filtrados. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-card-actions",
                { ref: "ofertas" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("2.1.3 Oferta por Título")]
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  " A funcionalidade de Criar Oferta dentro do título permite rápido acesso para criação ou edição de ofertas existentes para o título selecionado. "
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-7.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-7.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Função Criar Oferta")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Dentro da tela do Título, é possível acessar o botão Criar Oferta e gerar uma oferta exclusivamente para o título em questão "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Tela: Criar Nova Oferta")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Dentro da tela de ofertas, você pode inserir todos os parâmetros da funcionalidade a ser mostrado para o devedor, como por exemplo: porcentagem de desconto aplicado, valor do desconto em reais (R$), se oferece parcelamento em cartão ou boleto, Data de Início e data de fim da oferta. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " Por fim, como parte opcional, pode ativar o envio de uma mensagem SMS ao devedor contendo informações personalizáveis no corpo da mensagem. "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-8.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-8.png")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-card-title",
                { staticClass: "grey--text font-weight-black title" },
                [_vm._v("2.1.4 Renegociação")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " A tela de Renegociação, que pode ser acessada através do menu lateral permite rápido acesso a informações e status de ofertas gerais criadas para o Apresentante. Além de permitir o gerenciamento de todas as ofertas gerais existentes no sistema. "
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-9.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-9.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Listar Renegociações")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Utilize essa tela para realizar buscas dentro da base de Renegociações criadas no sistema. "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dense: "",
                        color: "primary white--text",
                        width: "100%"
                      }
                    },
                    [
                      _vm._v(" filtrar "),
                      _c("v-icon", { staticClass: "ml-2" }, [_vm._v("search")])
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Essa opção mostra na tela todos os resultados existentes, ou seja, irá mostrar todas as ofertas existentes no sistema, podendo utilizar o filtro para trazer resultados mais detalhados "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dense: "",
                        color: "success white--text",
                        width: "100%"
                      }
                    },
                    [
                      _vm._v(" Nova oferta "),
                      _c("v-icon", { staticClass: "ml-2" }, [_vm._v("add")])
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Essa opção irá gerar o procedimento de criação de uma nova oferta, onde serão informados os dados e parametros para a nova oferta a ser criada. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Criar Nova Oferta")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Dentro da tela Renegociação, através do botão Criar Nova Oferta, você poderá inserir uma nova oferta para todos os devedores que estarão inclusos nas condições que serão definidas a partir da faixa de valores. ( Exemplo: de R$ 100,00 a R$ 2.000,00) "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " Por fim, como parte opcional, pode ativar o envio de uma mensagem SMS ao devedor contendo informações personalizáveis no corpo da mensagem. "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-10.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-10.png")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-card-actions",
                { ref: "enviarSms" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("2.1.5 Enviar SMS")]
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  " A tela Enviar SMS, permite listar, editar e criar novos templates para envio de Mensagens SMS. "
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-11.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-11.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Listar SMS's")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Utilize essa tela para realizar buscas dentro da base de SMS's criados no sistema. "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dense: "",
                        color: "primary white--text",
                        width: "100%"
                      }
                    },
                    [
                      _vm._v(" filtrar "),
                      _c("v-icon", { staticClass: "ml-2" }, [_vm._v("search")])
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Essa opção mostra na tela todos os resultados existentes, ou seja, irá mostrar todas as ofertas existentes no sistema, podendo utilizar o filtro para trazer resultados mais detalhados "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dense: "",
                        color: "success white--text",
                        width: "100%"
                      }
                    },
                    [
                      _vm._v(" Criar Novo SMS "),
                      _c("v-icon", { staticClass: "ml-2" }, [_vm._v("add")])
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Essa opção irá abrir na tela uma nova mensagem SMS, onde poderá inserir os parâmetros e detalhes do envio. Também poderá personalizar a mensagem a ser enviada. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-card-title",
                { staticClass: "grey--text font-weight-black title" },
                [_vm._v("2.1.6 Gerenciar Usuários do Sistema")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " A tela Listar Usuários permite criar, editar e listar usuários para gerenciar o sistema Recuperi. A listagem de usuários é gerada automaticamente na tela, eliminando a necessidade de preenchimento de filtros ou clique de botão. "
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-12.png"),
                      "max-height": "750",
                      "max-width": "750",
                      src: require("@/assets/manual/screen-manual-12.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { display: "grid" }, attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Listar Usuários")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Utilize essa tela para gerenciar os usuários. A listagem exibe o nome e e-mail/login do usuário, bem como seu status (ativo ou inativo) e o Tipo do Usuário, que será detalhado posteriormente. Além disso, é possível consultar o "
                    ),
                    _c("b", [_vm._v("Histórico")]),
                    _vm._v(
                      ' de ações do Usuário no sistema, utilizando o botão na coluna "Histórico". Ao final da linha, temos o botão de editar. '
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dense: "",
                        color: "success white--text",
                        width: "100%"
                      }
                    },
                    [
                      _vm._v(" Criar Novo Usuário "),
                      _c("v-icon", { staticClass: "ml-2" }, [_vm._v("add")])
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Essa opção permite a criação de novos usuários no sistema definindo suas limitações e autorizações como Admin ou Usuário Ativo/Inativo. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "ma-3 pa-3",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "align-items": "center"
              }
            },
            [
              _c(
                "v-col",
                { staticStyle: { display: "grid" }, attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Criar Usuário")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Esta é a tela de criação de Usuário. Nela, é possível visualizar as permissões que cada Tipo de Usuário possui, e inserir os dados para a criação de um novo usuário do sistema. Caso deseje, também é possível delimitar um "
                    ),
                    _c("b", [_vm._v("Agrupador")]),
                    _vm._v(
                      " para este Usuário, o que irá delimitar a visibilidade que ele possui dos Títulos na Plataforma. Para mais detalhes sobre os Agrupadores, verificar o tópico "
                    ),
                    _c("b", [_vm._v("2.1.8")]),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      'A criação de senha é opcional, porém, o usuário deverá possuir um e-mail válido, pois será necessário que ele redefina a senha no primeiro acesso. Além disso, utilizando o ícone de "chave", é possível atribuir automaticamente uma senha que já respeita os padrões de segurança do sistema.'
                    ),
                    _c("br"),
                    _c("br"),
                    _c("b", [_vm._v("Importante:")]),
                    _vm._v(" apenas Usuários com permissão de "),
                    _c("b", [_vm._v("administrador")]),
                    _vm._v(" podem criar usuários. ")
                  ]),
                  _c("br"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dense: "",
                        color: "primary white--text",
                        width: "100%"
                      },
                      on: { click: _vm.ativaModalPermissoes }
                    },
                    [_vm._v(" Ver Permissões ")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Este botão permite visualizar as permissões atribuídas a cada Tipo de Usuário. "
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v("Os "),
                    _c("b", [_vm._v("Tipos de Usuário")]),
                    _vm._v(" disponíveis atualmente são "),
                    _c("b", [_vm._v("admin, basic e lite")]),
                    _vm._v(". Para o usuário "),
                    _c("b", [_vm._v("lite")]),
                    _vm._v(", há também a opção "),
                    _c("b", [_vm._v("somente leitura")]),
                    _vm._v(
                      ", destinada a usuários que devem ter permissão apenas de visualização de títulos. "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-20.png"),
                      "max-height": "750",
                      "max-width": "750",
                      src: require("@/assets/manual/screen-manual-20.png")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              ref: "dialog",
              attrs: { "max-width": "60vw", "max-height": "60vh" },
              model: {
                value: _vm.modalPermissoes,
                callback: function($$v) {
                  _vm.modalPermissoes = $$v
                },
                expression: "modalPermissoes"
              }
            },
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", dark: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.modalPermissoes = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex",
                          attrs: { cols: "1", sm: "9" }
                        },
                        [
                          _c("v-toolbar-title", [
                            _vm._v(" Permissões de Usuário ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex",
                          attrs: { cols: "1", sm: "3" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white primary--text",
                              on: { click: _vm.generatePDF }
                            },
                            [_vm._v("Exportar em PDF")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                {
                  staticClass: "py-4 px-8",
                  staticStyle: { "border-radius": "0" }
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "mx-0 mt-0 mb-3 pa-0",
                      staticStyle: { "font-weight": "500" }
                    },
                    [
                      _vm._v(
                        " Confira na tabela abaixo os detalhes das permissões para cada usuário: "
                      )
                    ]
                  ),
                  _c(
                    "v-card",
                    [
                      _c("v-data-table", {
                        ref: "myTable",
                        attrs: {
                          headers: _vm.headersPermissoes,
                          items: _vm.itensPermissoes,
                          "show-group-by": "",
                          "footer-props": {
                            itemsPerPageOptions: [100, 150, 200],
                            itemsPerPageText: "Itens por página:"
                          },
                          "group-by": "category",
                          height: "60vh"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `item.admin`,
                              fn: function({ item }) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: { color: _vm.getColor(item.admin) }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(_vm.getBolean(item.admin))
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: `item.basic`,
                              fn: function({ item }) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: { color: _vm.getColor(item.basic) }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(_vm.getBolean(item.basic))
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: `item.lite`,
                              fn: function({ item }) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: { color: _vm.getColor(item.lite) }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.getBolean(item.lite)))
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ]
            },
            [
              _c("v-data-table", {
                ref: "myTableShow",
                attrs: {
                  headers: _vm.headersPermissoes,
                  items: _vm.itensPermissoes,
                  "show-group-by": "",
                  "footer-props": {
                    itemsPerPageOptions: [100, 150, 200],
                    itemsPerPageText: "Itens por página:"
                  },
                  "group-by": "category",
                  height: "100%"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.admin`,
                      fn: function({ item }) {
                        return [
                          _c(
                            "v-chip",
                            { attrs: { color: _vm.getColor(item.admin) } },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.getBolean(item.admin)))
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: `item.basic`,
                      fn: function({ item }) {
                        return [
                          _c(
                            "v-chip",
                            { attrs: { color: _vm.getColor(item.basic) } },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.getBolean(item.basic)))
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: `item.lite`,
                      fn: function({ item }) {
                        return [
                          _c(
                            "v-chip",
                            { attrs: { color: _vm.getColor(item.lite) } },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.getBolean(item.lite)))
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "ma-3 pa-3",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "align-items": "center"
              }
            },
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-21.png"),
                      "max-height": "750",
                      "max-width": "750",
                      src: require("@/assets/manual/screen-manual-21.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { display: "grid" }, attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Editar Usuário")]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _vm._v(
                        " Esta é a tela de edição de Usuário. Nela, é possível alterar os dados de um usuário já cadastrado no sistema. "
                      ),
                      _c("br"),
                      _c("br"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey white--text",
                          attrs: { dense: "", width: "100%" }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              style: { color: "white" },
                              attrs: { medium: "" }
                            },
                            [_vm._v("edit")]
                          ),
                          _vm._v("Editar ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " A alteração de senha é opcional, assim como na criação, mas é possível que um administrador ajuste a senha de um usuário se necessário. A funcionalidade de gerar senha automaticamente, presente na criação de usuário, também está presente na edição."
                    ),
                    _c("br"),
                    _c("br"),
                    _c("b", [_vm._v("Importante:")]),
                    _vm._v(" apenas Usuários com permissão de "),
                    _c("b", [_vm._v("administrador")]),
                    _vm._v(" podem editar usuários. ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { ref: "alteracoesLote" },
            [
              _c(
                "v-row",
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("2.1.7 Alterações de Títulos em lote")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " A tela alterações de títulos em lote permitir realizar alterações em massa para determinados títulos no sistema, operações como: Cancelamento, Anuência e Desistência de titulos selecionados. Através dessa função, também é possível realizar o upload de um arquivo em formato XLS/XLSX ou XLSM, e após a leitura do arquivo, extrair títulos para executar o comando selecionado. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-13.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-13.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Cancelamento em Lote")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Utilize essa tela para realizar ações de cancelamento em lote para títulos do sistema. "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dense: "",
                        color: "success white--text",
                        width: "100%"
                      }
                    },
                    [
                      _vm._v(" Passo a Passo "),
                      _c("v-icon", { staticClass: "ml-2" }, [
                        _vm._v("mdi-chevron-down")
                      ])
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Dica: o cancelamento pode ser solicitado somente para os títulos com os seguintes status: 102 Protestado, 109 Protestado por Edital, 123 Protestado por Edital(RJ/BA) , 250 Solicitado Anuência, 251 Enviado à Anuência, 144 Anuência confirmada em Cartório "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Passo 1")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " No menu lateral do painel, clique em Alterações Lote, e em seguida na opção que deseja realizar: Cancelamento, Desistência ou Anuência. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Passo 2")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      ' Para realizar esse procedimento existem 2 opções: a primeira opção é através do campo de busca, onde através desse campo de texto o usuário pode colar uma sequência de números (títulos) separados por virgula (",") e clicar no botão "Filtrar". O sistema irá trazer em tela todos os resultados encontrados. '
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      ' A segunda opção para realizar a ação, é através de um upload de Arquivo: Na tela do procedimento que deseja executar, faça o Download do arquivo modelo através do botão "baixar modelo", ao baixar o modelo, o usuário poderá colar em sequencia todos os títulos que deseja realizar o cancelamento. Para realiar o upload de arquivo, vamos exemplificar nos próximos passos o procedimento. '
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-14.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-14.png")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "6" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-15.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-15.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Passo 3")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      ' Ao abrir o template de upload, o usuário irá colocar os títulos na coluna que deseja fazer o procedimento, linha após linha, cada número do título deve ser inserido em 1 linha. Utilize apenas a coluna "ds_nosso_numero" como na imagem exemplo ao lado. '
                    )
                  ]),
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Passo 4")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Após incluir todos os títulos que deseja realizar a ação, salve o arquivo e feche para realizar o upload dentro do painel. Exemplo será mostrado no próximo passo. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Passo 5")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      ' Através do campo de upload, insira o arquivo XLS, XLSX ou XLSM que será utilizado para aplicar a ação no sistema, após selecionar o arquivo dentro das pastas de seu computador, nosso verificador irá fazer a busca de títulos e mostrará quantos resultados foram encontrados no campo "Títulos verificados:", conforme a imagem ao lado. '
                    )
                  ]),
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Passo 6")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Após verificar os títulos que foram identificados no arquivo, clique em filtrar, o painel irá trazer na tela os resultados no formato lista, onde o Usuário tem a opção de selecionar apenas os títulos desejados ou todos através de um comando no final da tela. "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-16.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-16.png")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "6" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-17.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-17.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Passo 7")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Selecione todos os títulos que deseja efetuar o cancelamento, caso queira selecionar todos os títulos do resultado de uma só vez, utilize o comando em tela. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Passo 8")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      ' Após revisar os títulos selecionados, aplique o comando de Cancelamento através do botão "Cancelar selecionados" no final da tela. '
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Passo 9")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Selecione todos os títulos que deseja efetuar o cancelamento, caso queira selecionar todos os títulos do resultado de uma só vez, utilize o comando em tela. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Passo 10")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      ' Após revisar os títulos selecionados, aplique o comando de Cancelamento através do botão "Cancelar selecionados" no final da tela. '
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "6" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-18.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-18.png")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-manual-19.png"),
                      "max-height": "700",
                      "max-width": "700",
                      src: require("@/assets/manual/screen-manual-19.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Confirmação e Envio")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Irá Mostrar uma tela de Confirmação, com os títulos que estarão recebendo o comando de cancelamento, após revisá-los, clique em confirmar. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Finalizado, faça a verificação")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      ' Para conferir você pode ir em "Consulta de Títulos" e buscar os títulos, filtrando pela data de atualização e colocando o status Solicitado Desistência/Cancelamento/Desistência. Lembrar de fazer um arquivo para cada tipo de solicitação '
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-card-title",
                { staticClass: "grey--text font-weight-black title" },
                [_vm._v("2.1.8 Gerenciar Agrupadores do Sistema")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " A tela Listar Agrupadores permite criar, editar e listar Agrupadores. Assim como no gerenciamento de usuários, a listagem de Agrupadores é gerada automaticamente na tela. Existe um botão para a ressincronização dos dados, que realiza um novo carregamento da tela. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " O Agrupador consiste basicamente de um código, gerado automaticamente pela nossa plataforma, e um nome para identificação do Agrupador. O "
                ),
                _c("b", [_vm._v("conceito")]),
                _vm._v(
                  " de Agrupador foi introduzido na plataforma para permitir que um Apresentante setorize a visualização de seus Títulos. Sendo assim, Títulos vinculados a um Agrupador só poderão ser visualizados e acessados por aqueles Usuários que possuírem o mesmo código de Agrupador atribuído ao Título. Usuários que não possuem nenhum agrupador vinculado podem visualizar Títulos de todos os Agrupadores. "
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-agrupador-01.png"),
                      "max-height": "750",
                      "max-width": "750",
                      src: require("@/assets/manual/screen-agrupador-01.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { display: "grid" }, attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Listar Agrupadores")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Utilize essa tela para gerenciar os Agrupadores. A listagem exibe o ID do Agrupador, além do seu nome de identificação e código. Ao final da linha, temos o botão de editar. "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dense: "",
                        color: "success white--text",
                        width: "100%"
                      }
                    },
                    [
                      _vm._v(" Criar Novo Agrupador "),
                      _c("v-icon", { staticClass: "ml-2" }, [_vm._v("add")])
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Essa opção permite a criação de novos agrupadores no sistema. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "ma-3 pa-3",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "align-items": "center"
              }
            },
            [
              _c(
                "v-col",
                { staticStyle: { display: "grid" }, attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Criar Agrupador")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Esta é a tela de criação de Agrupador. É preciso preencher apenas o nome de identificação do Agrupador, uma vez que o código é gerado automaticamente pela nossa plataforma, e ficará disponível para consulta após a conclusão do requerimento de criação de novo Agrupador."
                    ),
                    _c("br"),
                    _c("br"),
                    _c("b", [_vm._v("Importante:")]),
                    _vm._v(" apenas Usuários com permissão de "),
                    _c("b", [_vm._v("administrador")]),
                    _vm._v(" podem gerenciar agrupadores. ")
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-agrupador-02.png"),
                      "max-height": "750",
                      "max-width": "750",
                      src: require("@/assets/manual/screen-agrupador-02.png")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "ma-3 pa-3",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "align-items": "center"
              }
            },
            [
              _c(
                "v-col",
                { attrs: { md: "8" } },
                [
                  _c("v-img", {
                    staticClass: "ma-3",
                    attrs: {
                      "lazy-src": require("@/assets/manual/screen-agrupador-03.png"),
                      "max-height": "750",
                      "max-width": "750",
                      src: require("@/assets/manual/screen-agrupador-03.png")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { display: "grid" }, attrs: { md: "4" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v("Editar Agrupador")]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _vm._v(
                        " Esta é a tela de edição de Agrupador. Nela, é possível alterar o nome de identificação de um Agrupador já cadastrado no sistema. Não é possível alterar o código, uma vez que ele é gerado automaticamente pelo sistema. "
                      ),
                      _c("br"),
                      _c("br"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey white--text",
                          attrs: { dense: "", width: "100%" }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              style: { color: "white" },
                              attrs: { medium: "" }
                            },
                            [_vm._v("edit")]
                          ),
                          _vm._v("Editar ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _c("b", [_vm._v("Importante:")]),
                    _vm._v(" apenas Usuários com permissão de "),
                    _c("b", [_vm._v("administrador")]),
                    _vm._v(" podem editar usuários. ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c("v-card-text", [
                _c("b", [_vm._v("Sobre o envio de Títulos:")]),
                _vm._v(
                  " ao realizar o upload de Títulos, será possível preencher na planilha de upload ou no envio via API o campo de Agrupador. Quando esse vínculo de Agrupador é feito, apenas usuários que possuírem o mesmo código de Agrupador que o Título poderão visualizá-lo. Além disso, usuários que não possuírem delimitação de Agrupador também conseguem visualizar Títulos com Agrupador (uma vez que eles não possuem essa limitação de visualização na Plataforma) "
                )
              ]),
              _c("v-card-text", [
                _c("b", [_vm._v("Sobre o vínculo entre Agrupador e Usuário:")]),
                _vm._v(" reforçando a informação do segmento "),
                _c("b", [_vm._v("2.1.6")]),
                _vm._v(
                  ", será possível atribuir um Agrupador para um Usuário tanto na Criação quanto na Edição de Usuários. Ao realizar esse vínculo, é possível delimitar o acesso que um Usuário tem aos Títulos do Apresentante, delimitando e segmentando esses acessos. A qualquer momento, é possível remover ou incluir uma delimitação de Agrupador para um usuário previamente cadastrado. "
                )
              ]),
              _c("v-card-text", [
                _c("b", [_vm._v("Sobre a pesquisa de Títulos com Agrupador:")]),
                _vm._v(
                  " usuários que possuírem um Agrupador definido sempre realizarão pesquisas de Título com o código de Agrupador embutido na busca. Sendo assim, um Usuário que possuir um Agrupador A01, por exemplo, sempre enviará esse dado como filtro na busca. Já os usuários que não possuírem um Agrupador vinculado poderão selecionar um dos Agrupadores cadastrados no Apresentante (caso o Apresentante utilize esse sistema) para realizar suas buscas de Título nas diversas telas do sistema em que a busca de Título é feita com filtros. "
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-card-title",
                { staticClass: "grey--text font-weight-black display-1" },
                [_vm._v("3. Nossa API")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " Nossa API REST-based permite o recebimento dos títulos via API (em formato JSON), para sensibilização a partir dos próprios sistemas legados. Essa opção permite integração com a nossa Plataforma, possibilitando maior agilidade por meio da automatização dos procedimentos de protesto. Dessa forma, a integração com nossa API permite que seus sistemas legados enviem diretamente para nossa plataforma as informações para protesto de títulos, bem como realizar as ações de cancelamento, anuência e desistência de protesto. É possível ainda consultar o status e movimentações de títulos já enviados. Destacamos que o apresentante pode se utilizar do Painel em conjunto com a API para acompanhamento, relatórios e envio de ações (desistência, anuência, cancelamento, entre outras). A classificação RESTful é normalmente a serviços que expõe à risca todas as características do REST, como trabalhar stateless, sessionless, entre outras. No nosso caso, por não expormos operações de CRUD com os verbos “add”, “put”, “delete”, por exemplo, consideramos os serviços RESTful, e sim baseado em REST. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Em linhas gerais, os sistemas legados realizam as seguintes operações via API: "
                )
              ]),
              _c(
                "v-col",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "grey--text font-weight-black title" },
                    [
                      _vm._v(
                        " Envio de Remessa: envio de títulos para protestos. Estes títulos são enviados para oscartórios em todo o Brasil. "
                      )
                    ]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Irregularidades cadastrais: são problemas estruturais nos dados informados. Estasirregularidades, caso existam, já são devolvidas no response da requisição. •Eventos acontecidos nos cartórios: São alterações que o título pode sofrer até oencerramento de seu ciclo, como irregularidades cartoriais*, pagamento dostítulos, cancelamento e anuência. São retornados depois e podem ser consultadospelos endpoints de consulta. *Irregularidades cartoriais: São irregularidades informadas pelos cartórios. "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", xs: "12", sm: "6" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "grey--text font-weight-black title" },
                    [
                      _vm._v(
                        " Envio de Cancelamentos, Anuências e Desistências: envio destes eventos aos cartórios. "
                      )
                    ]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " Cancelamento: quando enviado, o apresentante deve pagar as custas decancelamento referentes aos emolumentos dos cartórios (uma vez que oapresentante recebeu este valor de seus clientes). •Anuência: o apresentante comunica ao cartório a informação de que o valor dotítulo protestado já foi pago. Cabe ao devedor ir ao cartório para pagar osemolumentos e retirar o protesto. •Desistência: envio de tentativa de desistência de protesto, no meio tempo em queum título já foi enviado, mas ainda não foi efetivamente protestado. "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", xs: "12", sm: "6" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "grey--text font-weight-black title" },
                    [_vm._v(" Consulta de Titulo: ")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " consulta da base de título que já foi enviada, e permite parâmetros defiltragem. Este endpoint retorna os detalhes dos títulos, assim como todo o histórico deeventos de um título, como datas de envio, aceite, apontamento, pagamento etc. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-card-title",
                { staticClass: "grey--text font-weight-black title" },
                [_vm._v("3.1 Documentação ")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " Para acessar a documentação completa de API's, acesse o botão abaixo: "
                )
              ]),
              _c(
                "v-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            target: "_blank",
                            width: "100%",
                            dense: "",
                            color: "red",
                            href: "https://api-doc.recuperi.com.br"
                          }
                        },
                        [
                          _vm._v(" VER DOCUMENTAÇÃO "),
                          _c("v-icon", { staticClass: "ml-2" }, [
                            _vm._v("arrow_forward")
                          ])
                        ],
                        1
                      ),
                      _c("v-card-text", [
                        _vm._v(
                          " ou através da URL a seguir: https://api-doc.recuperi.com.br "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "grey--text font-weight-black display-1" },
                [_vm._v("4. Validação Básica dos Títulos ")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " O protesto deve ser realizado conforme definido pela legislação aplicável e em linha com as práticas adotadas pelos cartórios de protesto. Nesse sentido, nossa Plataforma foi desenvolvida com filtros que revisam o preenchimento de diversos campos – contudo, é de responsabilidade do usuário a veracidade e correto preenchimento das informações para envio dos títulos para protesto. Para evitar a rejeição de títulos pelos cartórios de protesto, algumas validações básicas são feitas pela Plataforma, de acordo com as orientações a seguir: "
                )
              ]),
              _c(
                "v-row",
                { staticClass: "ma-3 pa-3" },
                [
                  [
                    _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.desserts,
                        "items-per-page": 100,
                        "hide-default-header": true,
                        "hide-default-footer": true
                      }
                    })
                  ]
                ],
                2
              ),
              _c("v-card-text", [
                _vm._v(
                  " *Nome do Devedor e Endereço do Devedor: se informações foram enviadas com tamanhosuperior ao limite de 45 caracteres, a Plataforma não os rejeita, mas aplica regras automáticas de encurtamento. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Caso não seja possível a redução para 45 caracteres e a integração seja via EXCEL, o título fica “Em Revisão”. É possível editar estes campos manualmente para cumprir com o limite de 45 caracteres para liberar o seu envio. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Já no caso da integração via API, quando o limite de 45 caracteres não for atingido, o sistema retorna com irregularidade e o título precisa ser reenviado posteriormente, com as correções necessárias. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " ** Essas informações são fixas e disponibilizadas pela área Jurídica da Recuperi. Devem ser enviadas conforme orientação, para cada apresentante. "
                )
              ]),
              _c(
                "v-card-title",
                { staticClass: "grey--text font-weight-black display-1" },
                [_vm._v("5. Anexo: Glossário ")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " Anuência: similar ao Cancelamento, resulta no cancelamento do registro do protesto. Contudo, nessa hipótese apenas os valores protestados (ou outros valores, conforme renegociado com o Devedor) foram pagos pelo Devedor ao Apresentante – caberá ao Devedor realizar o pagamento dos emolumentos diretamente aos cartórios. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Apontamento: procedimento em que o Tabelião analisa o cumprimento dos requisitos legais do título apresentado para protesto. Ocorre antes da intimação do Devedor para fins de protesto. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Apresentante: denominação da empresa credora cadastrada perante os cartórios de protesto/Recuperi. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Cancelamento: trata-se do cancelamento do registro do protesto. Nessa hipótese, tanto os valores protestados (ou outros valores, conforme renegociado com o Devedor) quanto os emolumentos foram pagos ao Apresentante – caberá ao Apresentante realizar a transferência dos valores devidos aos cartórios. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Desistência: antes de efetuado o protesto, o Apresentante poderá desistir do procedimento, com ou sem pagamento de emolumentos a depender das ações já realizadas pelo cartório de protesto (por exemplo, se o Devedor já foi notificado). "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Emolumentos: taxas remuneratórias de serviços públicos (também conhecidas como “custos do cartório”). Devem ser pagas pelo Devedor (na Anuência) ou pelo Apresentante (no Cancelamento ou Desistência, quando aplicável). "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Protesto: é o ato formal e solene pelo qual se prova a inadimplência e o descumprimento de obrigação originada em títulos e outros documentos de dívida. O Protesto pode ser realizado por indicação, ou seja, apenas as informações do título são enviadas, sem a apresentação do documento original fisicamente. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Sustado Judicialmente: Título sustado por ordem judicial, ocorre antes da lavratura do protesto, até descisão final do processo. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Aceito ( L.C. e Duplicata): Títulos aceitos, utilizado Duplicatas e letras de Câmbio, para aceite do sacado. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Protestado por Edital (RJ/BA): Título protestado através da tentativa de intimação via edital, código de ocorrência utilizado somente nos estados BA e RJ. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Retirado por Edital: Retirada apresentada via edital, mesmo efeito da retirada via arquivo DP. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Cancelado Terceiro: Protesto de terceiro cancelado, através de anuência e/ou via arquivo AC/CP. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Desistência por Liquidação Bancária: Título já quitado, apresentante desiste de encaminhar título a protesto. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Sustado Definitivo: Título sustado definitivamente, ocorre após o término do processo de sustação judicial. "
                )
              ]),
              _c(
                "v-card-title",
                { staticClass: "grey--text font-weight-black display-1" },
                [_vm._v("6. Anexo: Irregularidades ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              [
                _c("v-data-table", {
                  staticClass: "elevation-1 ma-3 pa-3",
                  attrs: {
                    headers: _vm.irregularidadesHead,
                    items: _vm.irregularidadesDesc,
                    "items-per-page": 100,
                    "hide-default-header": true,
                    "hide-default-footer": true
                  }
                })
              ]
            ],
            2
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-card-title",
                { staticClass: "grey--text font-weight-black display-1" },
                [_vm._v(" 7. Anexo: Dicionário de Campos ")]
              ),
              [
                _c("v-data-table", {
                  staticClass: "elevation-1 ma-3 pa-3",
                  attrs: {
                    headers: _vm.dicionarioHead,
                    items: _vm.dicionarioDesc,
                    "items-per-page": 100,
                    "hide-default-header": true,
                    "hide-default-footer": true
                  }
                })
              ]
            ],
            2
          ),
          _c(
            "v-row",
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                "v-card-title",
                { staticClass: "grey--text font-weight-black display-1" },
                [_vm._v(" 8. Anexo: Tabela de tipos de espécies de títulos ")]
              ),
              [
                _c("v-data-table", {
                  staticClass: "elevation-1 ma-3 pa-3",
                  attrs: {
                    headers: _vm.headerEspecies,
                    items: _vm.especies,
                    "items-per-page": 100,
                    "hide-default-footer": ""
                  }
                })
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }